<template>
  <FormItem
    v-model="id"
    type="select"
    :values="roles"
    name="Ruolo"
    :rules="rules"
    :disabled="disabled"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :dense="dense"
    :clearable="clearable"
  />
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'RolesSelect',
  components: {
    FormItem,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    clearable: {
      default: false,
      type: Boolean,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 2,
    },
    xl: {
      type: [String, Number],
      default: 2,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    filter: {
      type: String,
    },
  },
  computed: {
    ...mapState('roles', ['list']),
    roles() {
      if (this.filter)
        return this.list.filter((r) => r.key.indexOf(this.filter) > -1 && r.key !== 'superadmin')

      // Filtra "SuperAdmin" solo quando non è presente un filtro
      return this.list.filter((r) => r.key !== 'superadmin');
    },
    id: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      },
    },
  },
  mounted() {
    this.getDropdownList({})
  },
  beforeDestroy() {
    this.resetList()
  },
  methods: {
    ...mapActions('roles', ['getDropdownList']),
    ...mapMutations('roles', {
      resetList: 'RESET_LIST',
    }),
  },
}
</script>
